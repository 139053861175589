.root {}

.inputError {
  border: var(--borderErrorField);
}

.showHide {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}

.passwordInput {
  position: relative;
}